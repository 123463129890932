@import '../setup/setup';

.typo {
  &--serif {
    font-family: $font-serif;
  }
}

// Sizes:
.typo {
  &--h1 {
    color: $color-black;
    font-size: var(--fs-h1);
    font-weight: 600;
  }

  &--h2 {
    color: $color-black;
    font-size: var(--fs-h2);
    font-weight: 600;
  }

  &--h3 {
    color: $color-black;
    font-size: var(--fs-h3);
    font-weight: 600;
    line-height: 1.2;
  }

  &--perex {
    font-size: var(--fs-perex);
    font-weight: 600;
  }

  &--body {
    font-size: var(--fs-body);
  }

  &--small {
    font-size: var(--fs-small);
  }

  &--caption {
    font-size: var(--fs-caption);
    font-weight: 600;
  }

  &--special {
    font-size: var(--fs-special);
    font-weight: 600;
  }

  &--quote {
    font-size: var(--fs-quote);
    font-weight: 600;
  }

  &--quote-author {
    font-size: var(--fs-quote-author);
  }
}
