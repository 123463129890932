// COLORS
// old blue:
//$color-blue: #2300ff;
// logo blue:
//$color-blue:#2301fe;

// their website blue:
$color-blue: #3e20ff;
$color-white: #fff;
$color-black: #232735;
$color-dark: #5d6376;
$color-orange: #f98037;
//$color-gray: #f2f2f2;
$color-gray: #fafafa;
$color-gray-darker: #bebebe;
$color-green: #0eefa2;
$color-pink: #f13be6;
$color-red: #ff6966;
$color-error: $color-red;
$color-yellow: #fed701;
$color-gold: #cab166;
$color-platinum: #ababab;

// SHADOWS
$shadow-main: 0 100px 80px rgba(0, 0, 0, 0.06), 0 42px 33px rgba(0, 0, 0, 0.04),
  0 22px 18px rgba(0, 0, 0, 0.04), 0 13px 10px rgba(0, 0, 0, 0.03),
  0 7px 5px rgba(0, 0, 0, 0.02), 0 3px 2px rgba(0, 0, 0, 0.02);

$shadow-item: 0 10px 30px rgba(0, 0, 0, 0.1);

$shadow-side: -100px 0 80px rgba(0, 0, 0, 0.06),
  -42px 42px 33px rgba(0, 0, 0, 0.04);

// FONTS
$font-sans: 'Montserrat', sans-serif;
$font-serif: 'Domaine', sans-serif;
$font-loading: 'Redacted', sans-serif;

// FONT SIZES
$fs-small: 13px;
$fs-label: 14px;
$fs-base: 16px;
$fs-lead: 16px;
$fs-h1: 51px;
$fs-h2: 50px;
$fs-h3: 38px;
$fs-h4: 30px;
$fs-h5: 24px;

// FONT SIZES tablet
$fs-tab-small: 13px;
$fs-tab-label: 14px;
$fs-tab-base: 14px;
$fs-tab-lead: 16px;
$fs-tab-h1: 42px;
$fs-tab-h2: 26px;
$fs-tab-h3: 24px;
$fs-tab-h4: 18px;
$fs-tab-h5: 22px;

// FONT SIZES Mobile
$fs-mob-small: 13px;
$fs-mob-label: 14px;
$fs-mob-base: 14px;
$fs-mob-lead: 16px;
$fs-mob-h1: 32px;
$fs-mob-h2: 26px;
$fs-mob-h3: 24px;
$fs-mob-h4: 18px;
$fs-mob-h5: 18px;

$font-type: (
  small: $fs-small,
  label: $fs-base,
  base: $fs-base,
  lead: $fs-lead,
  h1: $fs-h1,
  h2: $fs-h2,
  h3: $fs-h3,
  h4: $fs-h4,
  h5: $fs-h5,
) !default;

$font-device_ratio: (
  mobile: 0.75,
  tablet: 0.85,
  desktop: 0.9,
  desktop-wide: 1,
) !default;

// New font sizes use CSS vars -> globals.scss
