@import '../setup/setup';

.pageLogin {
  position: relative;
  padding-top: 60px;
  display: flex;
  height: 100%;
  //min-height: 80vh;
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;

  @include mq(tablet-wide) {
    flex-direction: row;
  }
}

.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 60px;
}

.bodyWrapper {
  width: 100%;
  //max-width: 400px;
  margin: 0 auto;

  @include mq(tablet-wide) {
    max-width: 345px;
  }
}

.header {
  position: relative;
}

.errorBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: $color-error;
  color: $color-white;
  padding: 18px 30px;
  text-align: center;
  font-size: 13px;

  a {
    font-weight: 600;
    text-decoration: underline;

    @include hover {
      text-decoration: none;
    }
  }
}

.successBox {
  background: $color-green;
  color: #000;
}

.title {
  //font-family: $font-sans;
  color: $color-black;
  //font-size: 42px;
  margin-bottom: 10px;
}

.titleSmaller {
  font-size: 30px;
}

.inputWrapper {
  margin-bottom: 30px;
}

.copy {
  font-size: 14px;
  line-height: 1.6;
  margin: 0 0 30px;
}

.linksWrapper {
  display: flex;
  //flex-direction: column;
  //margin-bottom: 60px;
  font-size: $fs-label;
  justify-content: space-between;
  margin-bottom: 15px;

  @include mq(tablet) {
    flex-direction: row;
  }
}

.col {
  flex-basis: 50%;

  & + & {
    flex-basis: 100%;
  }

  a {
    color: $color-blue;
    font-weight: 600;
    text-decoration: underline;

    @include hover {
      text-decoration: none;
    }
  }
}

@include mq(tablet-wide) {
  .pageLogin {
    padding-bottom: 0;
  }
}

.remember_me {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;

  label {
  }

  input[type='checkbox'] {
  }

  span {
    margin-left: 10px;
  }
}

.highlighted {
  border: 1px solid #3e20ff;
  text-align: center;
  display: block;
  padding: 0.8rem;
}
